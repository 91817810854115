import * as React from "react";

import "./global.css";
import "./spacings.css";
import "./typography.css";
import "./form.css";
import "./code.css";
import "./shake.css";
import "./iubenda.css";

export function GlobalStyles() {
  return <></>;
}
