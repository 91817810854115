exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-appsumo-tsx": () => import("./../../../src/pages/appsumo.tsx" /* webpackChunkName: "component---src-pages-appsumo-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cookie-tsx": () => import("./../../../src/pages/cookie.tsx" /* webpackChunkName: "component---src-pages-cookie-tsx" */),
  "component---src-pages-eula-tsx": () => import("./../../../src/pages/eula.tsx" /* webpackChunkName: "component---src-pages-eula-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notice-tsx": () => import("./../../../src/pages/notice.tsx" /* webpackChunkName: "component---src-pages-notice-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2000-01-01-draft-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2000-01-01-draft/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2000-01-01-draft-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2022-10-13-w-folio-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2022-10-13-w-folio/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2022-10-13-w-folio-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2022-11-21-arte-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2022-11-21-arte/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2022-11-21-arte-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2022-12-20-photobucket-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2022-12-20-photobucket/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2022-12-20-photobucket-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-03-17-how-startups-can-skip-every-stage-of-image-processing-grief-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-03-17-how-startups-can-skip-every-stage-of-image-processing-grief/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-03-17-how-startups-can-skip-every-stage-of-image-processing-grief-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-04-12-shoplazza-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-04-12-shoplazza/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-04-12-shoplazza-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-04-18-objkt-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-04-18-objkt/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-04-18-objkt-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-06-05-supabase-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-06-05-supabase/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-06-05-supabase-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-06-14-pinside-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-06-14-pinside/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-06-14-pinside-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-07-03-imobiliare-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-07-03-imobiliare/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-07-03-imobiliare-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-07-27-substack-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-07-27-substack/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-07-27-substack-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-11-03-product-live-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-11-03-product-live/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-11-03-product-live-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-11-21-smart-crop-tutorial-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2023-11-21-smart-crop-tutorial/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2023-11-21-smart-crop-tutorial-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2024-02-26-almost-free-image-processing-with-imgproxy-and-aws-lambda-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2024-02-26-almost-free-image-processing-with-imgproxy-and-aws-lambda/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2024-02-26-almost-free-image-processing-with-imgproxy-and-aws-lambda-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2024-04-02-image-servers-benchmark-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2024-04-02-image-servers-benchmark/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2024-04-02-image-servers-benchmark-index-mdx" */),
  "component---src-templates-blog-article-tsx-content-file-path-content-blog-2024-12-04-aws-ecs-es-2-warmpool-index-mdx": () => import("./../../../src/templates/blog-article.tsx?__contentFilePath=/opt/build/repo/content/blog/2024-12-04-aws-ecs-es2-warmpool/index.mdx" /* webpackChunkName: "component---src-templates-blog-article-tsx-content-file-path-content-blog-2024-12-04-aws-ecs-es-2-warmpool-index-mdx" */),
  "component---src-templates-landing-tsx-content-file-path-content-landings-imgproxy-vs-saas-index-mdx": () => import("./../../../src/templates/landing.tsx?__contentFilePath=/opt/build/repo/content/landings/imgproxy-vs-saas/index.mdx" /* webpackChunkName: "component---src-templates-landing-tsx-content-file-path-content-landings-imgproxy-vs-saas-index-mdx" */)
}

