import { useCallback, useEffect } from "react";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export interface IGTagEventData {
  event_category?: string;
  event_label?: string;
  event_value?: string | number;
}

export const sendGTagEvent = (action: string, data: IGTagEventData) => {
  typeof window !== "undefined" &&
    typeof window.dataLayer !== "undefined" &&
    window.dataLayer.push({
      event: "customEventToGA",
      eventName: action,
      eventCategory: data.event_category,
      eventLabel: data.event_label,
      eventValue: data.event_value,
    });
};

export const useGTag = (path: string) => {
  const handleClick = useCallback((event: any) => {
    const data: IGTagEventData = {};

    const action = event.target.dataset["gtagAction"];
    const category = event.target.dataset["gtagCategory"];
    const label = event.target.dataset["gtagLabel"];

    if (!action) return;
    if (category) data.event_category = category;
    if (label) data.event_label = label;

    sendGTagEvent(action, data);
  }, []);

  useEffect(() => {
    const elements = [...window.document.querySelectorAll("[data-gtag-action]")];

    elements.forEach((el: Element) => {
      el.addEventListener("click", handleClick);
    });

    return () => {
      elements.forEach((el: Element) => {
        el.removeEventListener("click", handleClick);
      });
    };
  }, [handleClick, path]);
};
