import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn:
    process.env.NODE_ENV === "production"
      ? "https://d097f73a19e1de52f5fe049af109d71d@o4506389254897664.ingest.sentry.io/4506389258108928"
      : undefined,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/api\.imgproxy\.net/],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});
