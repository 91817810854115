import * as React from "react";

import "intersection-observer";

import { useGTag } from "lib/events";
import { includeCaptcha } from "lib/captcha";
import { GlobalStyles } from "components/GlobalStyles/GlobalStyles";

import "./Layout.css";

interface Props {
  children: React.ReactNode;
  location: {
    pathname: string;
  };
}

export function Layout({ children, location }: Props) {
  useGTag(location.pathname);

  React.useEffect(() => {
    includeCaptcha();
  }, []);

  return (
    <>
      <GlobalStyles />

      <div className="layout t-body-s">{children}</div>
    </>
  );
}
